import React from "react"

import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 36px;

  .gatsby-image-wrapper {
    /* transform: scale(0.8); */
    transform-origin: left;
  }

  @media (min-width: 768px) {
    flex: 0 1 25px;
  }
  @media (min-width: 1024px) {
  }
`
function Logo() {
  return (
    <LogoWrap as={Link} to="/">
      <StaticImage
        src="../../images/logo.png"
        alt="Quantum Logo"
        placeholder="blurred"
        layout="fixed"
        width={160}
      />
    </LogoWrap>
  )
}

export default Logo
