import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled from "styled-components"

const FooterWrap = styled.footer`
  width: 100%;
  background-color: #09425a;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 0;

  @media (min-width: 768px) {
    display: grid;
    grid-template-areas: "image image" "top bottom" "copy copy";

    .gatsby-image-wrapper {
      grid-area: image;
      margin: 0 auto 1.5rem;
    }
  }
  @media (min-width: 1024px) {
    grid-template-areas: "image top" "image bottom" "copy copy";
    grid-template-columns: 1fr 4fr;
  }
`

const FooterTop = styled.div`
  ul {
    list-style: none;
  }
  @media (min-width: 768px) {
    grid-area: top;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: 1024px) {
    grid-area: bottom;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    max-width: 800px;
  }
`

const LinkSection = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;

  ul:first-child {
    margin-right: 3rem;
  }
  a {
    text-decoration: none;
    color: white;
  }

  @media (min-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    flex-direction: row;

    ul {
      display: flex;
      flex-direction: row;
    }
    ul:first-child {
      margin-right: 0rem;
    }
    a {
      margin-right: 1rem;
    }
  }
`

const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  .social-icon {
    margin: 0 0.5rem;
  }
`
const FooterBottom = styled.div`
  h4 {
    font-size: ${props => props.theme.fontSize.h5};
    margin-bottom: 0.5rem;
  }

  .footer-bottom-title {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    grid-area: bottom;
    display: flex;
    flex-direction: column;
    h4 {
      text-align: center;
    }
    hr {
      width: 90%;
      margin: 0 auto;
    }

    @media (min-width: 1024px) {
      /* .contactInfo__ContactInfoWrap-fryRuu {
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem auto 1rem 0;
        width: 90%;
      } */
      grid-area: top;
      max-width: 800px;
      .gatsby-image-wrapper {
        margin: 0 0.3rem 0 0;
      }
    }
  }
`

const FooterCopy = styled.div`
  border-top: 1px solid ${props => props.theme.colors.secondary};
  width: 100vw;
  p {
    text-align: center;
    margin: 0;
    padding: 0.5rem;
  }
  @media (min-width: 768px) {
    grid-area: copy;
  }
`

const ContactInfoWrap = styled.div`
  padding: 2rem 0;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  div {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    text-align: left;
  }

  .gatsby-image-wrapper {
    margin: 0 8px 0 0;
    width: 30px;
    height: 30px;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-content: center;
    padding: 0.5rem 0 1rem;

    h6 {
      font-size: ${props => props.theme.fontSize.p};
    }
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    width: 90%;
    margin: 0 auto;
  }
`
const ContactInfoItem = styled.div`
  margin: 0.5rem auto;
  max-width: 250px;

  @media (min-width: 768px) {
    align-items: center;
    justify-content: center;

    .gatsby-image-wrapper {
      align-self: center;
    }
  }
`

const Footer = ({ siteTitle }) => (
  <FooterWrap>
    <StaticImage
      src="../images/logo.png"
      alt="Quantum Logo"
      placeholder="blurred"
      layout="fixed"
      width={160}
    />

    <FooterBottom>
      <div className="footer-bottom-title">
        <h4>Schedule a Consultation</h4>
        <hr />
      </div>
      <ContactInfoWrap>
        <ContactInfoItem>
          <StaticImage
            src="../images/phone-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="phone icon"
          />
          <h6>(760) 585 - 4616</h6>
        </ContactInfoItem>
        <ContactInfoItem>
          <StaticImage
            src="../images/fax-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="fax icon"
          />
          <h6>(760) 444 - 1858</h6>
        </ContactInfoItem>
        <ContactInfoItem>
          <StaticImage
            src="../images/location-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="location icon"
          />
          <h6>
            5814 Van Allen Way,
            <br />
            Carslbad, CA 92008, Suite 212
          </h6>
        </ContactInfoItem>
        <ContactInfoItem>
          <StaticImage
            src="../images/hours-icon.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="hours icon"
          />
          <h6>
            MON, TUES, THURS, FRI
            <br />
            08:00am - 04:00 pm
          </h6>
        </ContactInfoItem>
      </ContactInfoWrap>
    </FooterBottom>
    <FooterTop>
      <LinkSection>
        <ul>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/staff">Staff</Link>
          </li>
          <li>
            <Link to="/media">Media</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/terms-of-use">Terms of Use</Link>
          </li>
          <li>
            <Link to="/medical-disclaimer">Medical Disclaimer</Link>
          </li>
        </ul>
      </LinkSection>
      <SocialIcons>
        <a
          href="https://www.facebook.com/qfmedwinkler/"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/fb-white.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Facebook icon"
            className="social-icon"
          />
        </a>

        <a
          href="https://www.linkedin.com/in/juergen-winkler-md-abihm-a3487290"
          target="_blank"
          rel="noreferrer"
        >
          <StaticImage
            src="../images/linkedin-white.png"
            width={30}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="LinkedIn icon"
            className="social-icon"
          />
        </a>
      </SocialIcons>
    </FooterTop>

    <FooterCopy>
      <p>
        {" "}
        © {new Date().getFullYear()}, {siteTitle}
      </p>
    </FooterCopy>
  </FooterWrap>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: `Quantum Functional Medicine`,
}

export default Footer
