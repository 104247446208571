import * as React from "react"
import styled from "styled-components"

import Navbar from "./Navbar"

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.primary};
  height: auto;
`

const Header = () => (
  <HeaderWrapper>
    <Navbar />
  </HeaderWrapper>
)

export default Header
